import React from 'react'
import PropTypes from 'prop-types'
import { useState } from 'react'
import {
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material'
import { Button } from 'react-admin'
import {
  blockInvalidCharFromNumberInput,
  numberInputOnWheelPreventChange,
} from '../../../../Utils/ConversionUtils'
import {
  BMS_SALVAGE_AGENT_ID,
  SHORE_CARS_AGENT_ID,
} from '../../../../Utils/ConstantsUtils'
import ReassignLeadButton from '../ReassignLeadButton'

const AssignToCopart = ({ sb, setPopupPanelName, setPopupPanelProps }) => {
  const [reserveValidationError, setReserveValidationError] = useState(null)
  const [reserve, setReserve] = useState('')
  const [purchasePriceValidationError, setPurchasePriceValidationError] =
    useState(null)
  const [purchasePrice, setPurchasePrice] = useState('')
  const [extraWorkCostValidationError, setExtraWorkCostValidationError] =
    useState(null)
  const [extraWorkCost, setExtraWorkCost] = useState('')
  const [
    desiredAuctionPriceValidationError,
    setDesiredAuctionPriceValidationError,
  ] = useState(null)
  const [desiredAuctionPrice, setDesiredAuctionPrice] = useState('')
  const [driveCondition, setDriveCondition] = useState('')
  const [driveConditionValidationError, setDriveConditionValidationError] =
    useState(null)
  const [rollCondition, setRollCondition] = useState('')
  const [rollConditionValidationError, setRollConditionValidationError] =
    useState(null)
  const [addressLine1ValidationError, setAddressLine1ValidationError] =
    useState(null)
  const [addressLine1, setAddressLine1] = useState(
    'Shore Cars Unit 2C Purchase Farm'
  )
  const [addressLine2ValidationError, setAddressLine2ValidationError] =
    useState(null)
  const [addressLine2, setAddressLine2] = useState('Easton Lane')
  const [primaryDamageValidationError, setPrimaryDamageValidationError] =
    useState(null)
  const [postcode, setPostcode] = useState('PO20 7NU')
  const [postcodeValidationError, setPostcodeValidationError] = useState(null)
  const [city, setCity] = useState('Sidlesham, Chichester')
  const [cityValidationError, setCityValidationError] = useState(null)
  const [primaryDamage, setPrimaryDamage] = useState('')
  const [strippedPartsValidationError, setStrippedPartsValidationError] =
    useState(null)
  const [strippedParts, setStrippedParts] = useState('')
  const [hasKeys, setHasKeys] = useState(false)
  const [personalsRemoved, setPersonalsRemoved] = useState(true)
  const [transporterAccess, setTransporterAccess] = useState(true)

  const handleReserveChange = (event) => {
    setReserveValidationError('')
    if (event.target.value <= 0) {
      setReserveValidationError('Reserve must be more than 0')
    }

    setReserve(event.target.value)
  }

  const handlePurchasePriceChange = (event) => {
    setPurchasePriceValidationError('')
    if (event.target.value < 0) {
      setPurchasePriceValidationError('Purchase Price must be at least 0')
    }

    setPurchasePrice(event.target.value)
  }

  const handleExtraWorkCostChange = (event) => {
    setExtraWorkCostValidationError('')
    if (event.target.value < 0) {
      setExtraWorkCostValidationError('Extra work cost must be at least 0')
    }

    setExtraWorkCost(event.target.value)
  }

  const handleDesiredAuctionPriceChange = (event) => {
    setDesiredAuctionPriceValidationError('')
    if (event.target.value < 0) {
      setDesiredAuctionPriceValidationError(
        'Desired auction price must be at least 0'
      )
    }

    setDesiredAuctionPrice(event.target.value)
  }

  const handleDriveConditionChange = (event) => {
    setDriveConditionValidationError('')
    setDriveCondition(event.target.value)
  }

  const handleRollConditionChange = (event) => {
    setRollConditionValidationError('')
    setRollCondition(event.target.value)
  }

  const handleAddressLine1Change = (event) => {
    setAddressLine1ValidationError('')

    setAddressLine1(event.target.value)
  }

  const handleAddressLine2Change = (event) => {
    setAddressLine2ValidationError('')

    setAddressLine2(event.target.value)
  }

  const handlePrimaryDamageChange = (event) => {
    setPrimaryDamageValidationError('')

    setPrimaryDamage(event.target.value)
  }

  const handlePostcodeChange = (event) => {
    setPostcodeValidationError('')

    setPostcode(event.target.value)
  }

  const handleCityChange = (event) => {
    setCityValidationError('')

    setCity(event.target.value)
  }

  const handleStrippedPartsChange = (event) => {
    setStrippedPartsValidationError('')

    setStrippedParts(event.target.value)
  }

  const handleHasKeysChange = () => {
    setHasKeys(!hasKeys)
  }
  const handlePersonalsRemovedChange = () => {
    setPersonalsRemoved(!personalsRemoved)
  }

  const handleTransporterAccessChange = () => {
    setTransporterAccess(!transporterAccess)
  }

  const handleButtonClick = () => {
    let validationError = false

    if (reserve === '') {
      setReserveValidationError('Reserve must be set')
      validationError = true
    }
    if (purchasePrice === '') {
      setPurchasePriceValidationError('Purchase price must be set')
      validationError = true
    }
    if (extraWorkCost === '') {
      setExtraWorkCostValidationError('Extra work cost must be set')
      validationError = true
    }
    if (desiredAuctionPrice === '') {
      setDesiredAuctionPriceValidationError('Desired auction price must be set')
      validationError = true
    }
    if (driveCondition === '') {
      setDriveConditionValidationError('Drive condition must be set')
      validationError = true
    }
    if (rollCondition === '') {
      setRollConditionValidationError('Roll condition must be set')
      validationError = true
    }

    if (addressLine1 === '') {
      setAddressLine1ValidationError('Address Line 1 must be set')
      validationError = true
    }

    if (city === '') {
      setCityValidationError('City must be set')
      validationError = true
    }

    if (postcode === '') {
      setPostcodeValidationError('Postcode must be set')
      validationError = true
    }
    if (validationError) return

    setPopupPanelProps({
      scPurchasePrice: purchasePrice,
      scExtraWorkCost: extraWorkCost,
      scDesiredAuctionPrice: desiredAuctionPrice,
      scDriveCondition: driveCondition,
      scAddressLine1: addressLine1,
      scAddressLine2: addressLine2,
      scPostcode: postcode,
      scCity: city,
      scHasKeys: hasKeys,
      scRollCondition: rollCondition,
      scPrimaryDamage: primaryDamage,
      scStrippedParts: strippedParts,
      scPersonalsRemoved: personalsRemoved,
      scTransporterAccess: transporterAccess,
    })
    setPopupPanelName('premiumLead')
  }

  return (
    <React.Fragment>
      <Grid item xs={3}></Grid>
      <Grid item xs={6}>
        <Grid container={true} columnSpacing={'1em'}>
          <Grid item xs={6}>
            <TextField
              id="reserve-assign-copart"
              label="Reserve"
              variant="outlined"
              type="number"
              value={reserve}
              onWheel={numberInputOnWheelPreventChange}
              onKeyDown={blockInvalidCharFromNumberInput}
              onChange={handleReserveChange}
              error={!!reserveValidationError}
              helperText={reserveValidationError ?? ' '}
              sx={{
                '& .MuiInputBase-root': {
                  height: '40px',
                  minHeight: '40px',
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="sc-purchase-price"
              label="Purchase Price"
              variant="outlined"
              type="number"
              value={purchasePrice}
              onWheel={numberInputOnWheelPreventChange}
              onKeyDown={blockInvalidCharFromNumberInput}
              onChange={handlePurchasePriceChange}
              error={!!purchasePriceValidationError}
              helperText={purchasePriceValidationError ?? ' '}
              sx={{
                '& .MuiInputBase-root': {
                  height: '40px',
                  minHeight: '40px',
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="sc-extra-work-cost"
              label="Extra Work Cost"
              variant="outlined"
              type="number"
              value={extraWorkCost}
              onWheel={numberInputOnWheelPreventChange}
              onKeyDown={blockInvalidCharFromNumberInput}
              onChange={handleExtraWorkCostChange}
              error={!!extraWorkCostValidationError}
              helperText={extraWorkCostValidationError ?? ' '}
              sx={{
                '& .MuiInputBase-root': {
                  height: '40px',
                  minHeight: '40px',
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="sc-desired-auction-price"
              label="Desired Auction Price"
              variant="outlined"
              type="number"
              value={desiredAuctionPrice}
              onWheel={numberInputOnWheelPreventChange}
              onKeyDown={blockInvalidCharFromNumberInput}
              onChange={handleDesiredAuctionPriceChange}
              error={!!desiredAuctionPriceValidationError}
              helperText={desiredAuctionPriceValidationError ?? ' '}
              sx={{
                '& .MuiInputBase-root': {
                  height: '40px',
                  minHeight: '40px',
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="sc-drive-condition"
              label="Drive Condition"
              variant="outlined"
              select
              value={driveCondition}
              onChange={handleDriveConditionChange}
              error={!!driveConditionValidationError}
              helperText={driveConditionValidationError ?? ''}
              sx={{
                '& .MuiInputBase-root': {
                  height: '40px',
                  minHeight: '40px',
                },
              }}
            >
              <MenuItem value="doesnt_start">Doesn&apos;t Start</MenuItem>
              <MenuItem value="drives">Drives</MenuItem>
              <MenuItem value="starts">Starts</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="sc-roll-condition"
              label="Roll Condition"
              variant="outlined"
              select
              value={rollCondition}
              onChange={handleRollConditionChange}
              error={!!rollConditionValidationError}
              helperText={rollConditionValidationError ?? ''}
              sx={{
                '& .MuiInputBase-root': {
                  height: '40px',
                  minHeight: '40px',
                },
              }}
            >
              <MenuItem value="drives">Drives</MenuItem>
              <MenuItem value="rolls">Rolls</MenuItem>
              <MenuItem value="doesnt_roll">Doesn&apos;t Roll</MenuItem>
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="sc-address-line-1"
              label="Address Line 1"
              variant="outlined"
              type="text"
              value={addressLine1}
              onChange={handleAddressLine1Change}
              error={!!addressLine1ValidationError}
              helperText={addressLine1ValidationError ?? ' '}
              sx={{
                '& .MuiInputBase-root': {
                  height: '40px',
                  minHeight: '40px',
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="sc-address-line-2"
              label="Address Line 2"
              variant="outlined"
              type="text"
              value={addressLine2}
              onChange={handleAddressLine2Change}
              error={!!addressLine2ValidationError}
              helperText={addressLine2ValidationError ?? ' '}
              sx={{
                '& .MuiInputBase-root': {
                  height: '40px',
                  minHeight: '40px',
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="sc-postcode"
              label="Postcode"
              variant="outlined"
              type="text"
              value={postcode}
              onChange={handlePostcodeChange}
              error={!!postcodeValidationError}
              helperText={postcodeValidationError ?? ' '}
              sx={{
                '& .MuiInputBase-root': {
                  height: '40px',
                  minHeight: '40px',
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="sc-city"
              label="City"
              variant="outlined"
              type="text"
              value={city}
              onChange={handleCityChange}
              error={!!cityValidationError}
              helperText={cityValidationError ?? ' '}
              sx={{
                '& .MuiInputBase-root': {
                  height: '40px',
                  minHeight: '40px',
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="sc-primary-damage"
              label="Primary Damage"
              variant="outlined"
              multiline={true}
              type="text"
              value={primaryDamage}
              onChange={handlePrimaryDamageChange}
              error={!!primaryDamageValidationError}
              helperText={primaryDamageValidationError ?? ' '}
              sx={{
                '& .MuiInputBase-root': {
                  minHeight: '40px',
                  paddingY: '1em',
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              id="sc-stripped-parts"
              label="Stripped parts"
              variant="outlined"
              multiline={true}
              type="text"
              value={strippedParts}
              onChange={handleStrippedPartsChange}
              error={!!strippedPartsValidationError}
              helperText={strippedPartsValidationError ?? ' '}
              sx={{
                '& .MuiInputBase-root': {
                  minHeight: '40px',
                  paddingY: '1em',
                },
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                color={`${hasKeys ? 'success' : 'error'}`}
                checked={hasKeys}
                onClick={handleHasKeysChange}
              />
            }
            label={'Has Keys?'}
          />
          <FormControlLabel
            control={
              <Checkbox
                color={`${transporterAccess ? 'success' : 'error'}`}
                checked={transporterAccess}
                onClick={handleTransporterAccessChange}
              />
            }
            label={'Transporter Access?'}
          />
          <FormControlLabel
            control={
              <Checkbox
                color={`${personalsRemoved ? 'success' : 'error'}`}
                checked={personalsRemoved}
                onClick={handlePersonalsRemovedChange}
              />
            }
            label={'Personals Removed?'}
          />
        </Grid>
      </Grid>
      <Grid item xs={3}></Grid>
      <Grid item xs={5}></Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          color="secondary"
          label="Assign to copart"
          fullWidth
          onClick={handleButtonClick}
          sx={{
            border: '2px solid #FFF !important',
            backgroundColor: '#13A310',
            color: '#FFF',
            height: '40px',
            fontWeight: '700',
            textTransform: 'uppercase',
            '&:hover': {
              backgroundColor: '#13A310',
            },
          }}
        ></Button>
        <ReassignLeadButton
          currentAgentId={SHORE_CARS_AGENT_ID}
          newAgentId={BMS_SALVAGE_AGENT_ID}
          buttonLabel="Transfer to BMS Salvage"
        />
      </Grid>
    </React.Fragment>
  )
}

AssignToCopart.propTypes = {
  sb: PropTypes.object,
  setPopupPanelName: PropTypes.func,
  setPopupPanelProps: PropTypes.func,
}

export default AssignToCopart
