import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded'
import VisibilityIcon from '@mui/icons-material/Visibility'
import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@mui/material'
import { blueGrey } from '@mui/material/colors'
import React, { useRef, useState } from 'react'
import {
  ArrayField,
  Edit,
  useNotify,
  useRecordContext,
  useRefresh,
  WithRecord,
} from 'react-admin'
import { useLocation } from 'react-router-dom'
import { sendLog } from '../../Class/AuditLog'
import AdminNotesPanel from '../../Components/QuoteRequest/Edit/AdminNotesPanel'
import CapPanel from '../../Components/QuoteRequest/Edit/CapPanel'
import CustomerNotesPanel from '../../Components/QuoteRequest/Edit/CustomerNotesPanel'
import VehicleNotesPanel from '../../Components/QuoteRequest/Edit/VehicleNotesPanel'
import BookInPanel from '../../Components/QuoteRequest/Edit/BookInPanel'
import MitelDataPanel from '../../Components/QuoteRequest/Edit/MitelDataPanel'
import {
  CollectionPanel,
  CustomerInfoPanel,
  HistoryTabbedPanel,
  LeadIDPanel,
  VehicleDetailsPanel,
  ViewAgentsPanel,
} from '../../Components/QuoteRequest/Edit/Panels'
import PricingNotesPanel from '../../Components/QuoteRequest/Edit/PricingNotesPanel'
import VehicleAdjustments from '../../Components/VehicleAdjustments'
import { checkIfUserHasExternalRole } from '../../Utils/HelperUtils'
import { getNextLead } from '../../Utils/QuoteRequestUtils'
import {
  getAdjustmentsSettings,
  getVehicleAdjustments,
  handleLeadLocking,
  saveVehicleNote,
} from '../../Utils/RestUtils'
import { SalvageBooking } from '../../Components/QuoteRequest'
import { ArrowBackIosRounded } from '@mui/icons-material'
import { useGetIdentity } from 'ra-core'

const EditQuoteRequest = (props) => {
  const refresh = useRefresh()
  const [vehicleAdjustments, setVehicleAdjustments] = useState([])
  const [adjustmentsSettings, setAdjustmentsSettings] = useState([])
  const [nextGridRow, setNextGridRow] = useState(7)
  const [historyPanelSelectedTab, setHistoryPanelSelectedTab] = useState(0)
  const [notes, setNotes] = useState({
    salvageNotes: null,
    quoteNotes: null,
  })
  const [postcodeChanged, setPostcodeChanged] = useState(false)
  const notify = useNotify()
  const notesRef = useRef()
  const overlayRef = useRef(null)
  const record = useRecordContext()
  const { identity, isLoading: identityLoading } = useGetIdentity()

  window.localStorage.setItem('recalculate', 'false')

  const [initialLoad, setInitialLoad] = useState(true)

  const saveNewVehicleNote = async (newVehicleNote, originId) => {
    notesRef.current = notes
    const response = await saveVehicleNote({
      leadId: originId,
      payload: newVehicleNote,
    })

    if (response.result === 'success') {
      sendLog(
        originId,
        identity.fullName + ' added vehicle note: ' + newVehicleNote
      )
      setNotes({
        salvageNotes: notes.salvageNotes,
        quoteNotes: newVehicleNote,
      })
    } else {
      notify(response.error_msg, { type: 'error' })
    }
  }

  const { state } = useLocation()
  let fetching = false
  let fetchingAdjustments = false
  let defaultView = 'list'

  if (state != null && state.contactLogScreen != null) {
    defaultView = state.contactLogScreen
  }

  let isExternal = checkIfUserHasExternalRole()

  const [contactLogScreen, setContactLogScreen] = useState(defaultView)

  const handleVehicleChange = async (vehicleIri) => {
    try {
      if (!fetchingAdjustments) {
        fetchingAdjustments = true
        const vehicle = await getVehicleAdjustments(vehicleIri)
        const adjSettings = await getAdjustmentsSettings()

        if (vehicleAdjustments.length === 0 && !fetching) {
          fetching = true
          setVehicleAdjustments(vehicle.vehicleAdjustments)
          setAdjustmentsSettings(adjSettings)
        } else if (
          JSON.stringify(vehicleAdjustments) !==
            JSON.stringify(vehicle.vehicleAdjustments) &&
          !fetching
        ) {
          fetching = true
          setVehicleAdjustments(vehicle.vehicleAdjustments)
        }
      }
    } catch (error) {
      notify(error, { type: 'error' })
    }
  }

  const transformData = (data) => {
    data.leadContact.vehicle.vehicleInfo.alloyWheels = parseInt(
      data.leadContact.vehicle.vehicleInfo.alloyWheels
    )

    data.leadContact.vehicle.vehicleInfo.previousKeepers = parseInt(
      data.leadContact.vehicle.vehicleInfo.previousKeepers
    )

    data.quotePaymentDetails.acc = parseInt(data.quotePaymentDetails.acc)

    data.quotePaymentDetails.sort = parseInt(data.quotePaymentDetails.sort)
    return data
  }

  const Adjustments = () => {
    const record = useRecordContext()
    if (record) {
      handleVehicleChange(record.id)
    }

    return (
      vehicleAdjustments.length !== 0 && (
        <ArrayField
          source="vehicleAdjustments"
          label={false}
          formClassName="vehicle-adjustments"
        >
          <VehicleAdjustments
            props={props}
            adjustments={vehicleAdjustments}
            adjustmentsSettings={adjustmentsSettings}
          />
        </ArrayField>
      )
    )
  }

  const SalvageSection = (props) => {
    const record = useRecordContext()
    setNextGridRow(10)
    if (record) {
      const isSalvage = record.leadContact.vehicle.tooGoodToScrap
    }
  }

  const GetNextButton = (props) => {
    const record = useRecordContext(props)

    const handleCloseLeadClick = async (record) => {
      sendLog(record.originId, 'Close lead button clicked', 'LOG_GET_NEXT')
      if (
        confirm(
          'This will log you out of this lead, are you finished working on this lead?'
        )
      ) {
        sendLog(record.originId, 'Close lead confirmed', 'LOG_GET_NEXT')

        handleLeadLocking('unlock', record.originId)

        location.href = '/#/'
      }
    }

    const handleButtonClick = async (record) => {
      sendLog(record.originId, 'Get next button clicked', 'LOG_GET_NEXT')
      if (
        confirm(
          'This will log you out of this lead, are you finished working on this lead?'
        )
      ) {
        sendLog(record.originId, 'Get next confirmed', 'LOG_GET_NEXT')
        const result = await getNextLead(record.originId)

        if (result.status === 'success') {
          handleLeadLocking('lock', result.id)
          location.href =
            '/#/quote-requests/%2Fapi%2Fquote-requests%2F' + result.id
          refresh()
        } else {
          notify(result.message, { type: 'error' })
        }
      }
    }

    return (
      <Grid
        style={{
          gridColumn: '1 / span 6',
          gridRowStart: 1,
          gridRowEnd: 1,
        }}
      >
        <Card style={{ height: '100%' }}>
          <CardContent
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              sx={{
                borderWidth: '0px !important',
              }}
              variant="contained"
              startIcon={<ArrowBackIosRounded />}
              onClick={() => handleCloseLeadClick(record)}
            >
              Back to dashboard
            </Button>
            <Button
              sx={{
                borderWidth: '0px !important',
              }}
              variant="contained"
              endIcon={<ArrowForwardIosRoundedIcon />}
              onClick={() => handleButtonClick(record)}
            >
              Get next lead
            </Button>
          </CardContent>
        </Card>
      </Grid>
    )
  }

  const DisableLead = (props) => {
    const record = useRecordContext(props)
    if (
      record.leadContact.vehicle.vehicleCollection &&
      record.leadContact.vehicle.vehicleCollection.offerSelected.collector
    ) {
      return (
        <div
          style={{
            background: 'black',
            height: 'calc(100vh + 50px)',
            opacity: '0.5',
            display: 'block',
            position: 'fixed',
            top: '-50px',
            width: '100vw',
            zIndex: '99999',
          }}
        ></div>
      )
    }
  }

  let isAdmin = false
  const roles = localStorage.getItem('permissions').split(',')

  if (
    roles.includes('ROLE_ADMIN') ||
    roles.includes('ROLE_SALVAGE') ||
    roles.includes('ROLE_SHORE')
  ) {
    isAdmin = true
  }

  return (
    <Edit
      {...props}
      title="Edit Lead"
      actions={false}
      redirect={false}
      transform={transformData}
      component="div"
      sx={{ '& .RaEdit-noActions': { marginTop: '0 !important' } }}
    >
      <Grid
        style={{
          display: 'grid',
          gap: '0.5rem',
          gridAutoFlow: 'row dense',
          gridTemplateColumns: 'repeat(6, 1fr)',
        }}
      >
        {isExternal && <GetNextButton {...props} />}
        <Grid
          style={{ gridColumn: '1 / span 2', gridRowStart: 2, gridRowEnd: 2 }}
        >
          <Card style={{ height: '100%' }}>
            <CardContent sx={{ paddingBottom: '0' }}>
              <LeadIDPanel {...props} />
            </CardContent>
          </Card>
        </Grid>
        <Grid
          style={{ gridColumn: '3 / span 2', gridRowStart: 2, gridRowEnd: 2 }}
        >
          <Card style={{ height: '100%', position: 'relative' }}>
            <WithRecord
              render={(record) => {
                // If the lead is SC and the user is salvage then the customer details must be obscured
                if (
                  roles.includes('ROLE_SALVAGE') &&
                  record.leadContact.vehicle.vehicleSalvage.assignedTo == 'cars'
                ) {
                  return (
                    <>
                      <Box
                        ref={overlayRef}
                        sx={{
                          backgroundColor: 'rgba(#000, 0.6)',
                          backdropFilter: 'blur(4px)',
                          height: '100%',
                          position: 'absolute',
                          width: '100%',
                          zIndex: 1000,
                        }}
                      >
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            height: '100%',
                          }}
                        >
                          <ButtonBase
                            onClick={() => {
                              sendLog(
                                record.originId,
                                `User ${identity.fullName} viewed customer details panel`
                              )
                              refresh()
                              overlayRef.current.remove()
                            }}
                            sx={{ display: 'flex', flexWrap: 'wrap' }}
                          >
                            <VisibilityIcon />
                            <Typography sx={{ ml: 1 }}>
                              Click to reveal
                            </Typography>
                            <Typography width={'100%'} fontSize="10px">
                              This action will be logged
                            </Typography>
                          </ButtonBase>
                        </Box>
                      </Box>
                    </>
                  )
                }
              }}
            />
            <CardContent>
              <CustomerInfoPanel
                {...props}
                setContactLogScreen={setContactLogScreen}
                setHistoryPanelSelectedTab={setHistoryPanelSelectedTab}
                setPostCodeChanged={setPostcodeChanged}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid
          style={{ gridColumn: '5 / span 2', gridRowStart: 2, gridRowEnd: 4 }}
        >
          <Card style={{ height: '100%' }}>
            <CardContent>
              <VehicleDetailsPanel />
            </CardContent>
          </Card>
        </Grid>
        <Grid
          style={{ gridColumn: '1 / span 4', gridRowStart: 3, gridRowEnd: 4 }}
        >
          <Card
            style={{
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <CardContent sx={{ width: '100%' }}>
              <CollectionPanel />
            </CardContent>
          </Card>
        </Grid>

        <WithRecord
          render={(record) => {
            const isSalvage = record.leadContact.vehicle.tooGoodToScrap
            const roles = localStorage.getItem('permissions').split(',')

            if (
              roles.includes('ROLE_ADMIN') ||
              roles.includes('ROLE_SALVAGE') ||
              roles.includes('ROLE_SHORE')
            ) {
              return (
                <Grid
                  style={{
                    gridColumn: '1 / span 6',
                    gridRow: 4,
                  }}
                >
                  <Card>
                    <CardContent>
                      <SalvageBooking userRoles={roles} />
                    </CardContent>
                  </Card>
                </Grid>
              )
            }
          }}
        />

        <Grid
          style={{
            gridColumn: '1 / span 3',
            gridRow: '5 / span 2',
            gridAutoRows: '1fr',
          }}
        >
          <Card sx={{ height: '100%', position: 'relative' }}>
            <CardContent sx={{ padding: '0 !important' }}>
              <div id="start-call-container"></div>
              <HistoryTabbedPanel
                {...props}
                setContactLogScreen={setContactLogScreen}
                contactLogScreen={contactLogScreen}
                setHistoryPanelSelectedTab={setHistoryPanelSelectedTab}
                historyPanelSelectedTab={historyPanelSelectedTab}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid
          style={{
            gridColumn: '4 / span 3',
            gridRowStart: 5,
            gridRowEnd: 5,
          }}
        >
          <Card>
            <CardHeader
              title="Admin Notes"
              sx={{
                padding: '10px',
                marginBottom: '1rem',
              }}
              titleTypographyProps={{
                sx: {
                  marginBottom: '-15px',
                  textTransform: 'uppercase',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: blueGrey,
                },
              }}
            />
            <CardContent
              sx={{
                paddingTop: '0',
                '&:last-child': {
                  paddingBottom: '18px',
                },
              }}
            >
              <AdminNotesPanel />
            </CardContent>
          </Card>
        </Grid>
        <Grid
          style={{ gridColumn: '4 / span 3', gridRowStart: 6, gridRowEnd: 6 }}
        >
          <Card>
            <CardHeader
              title="Pricing Notes"
              sx={{
                padding: '10px',
              }}
              titleTypographyProps={{
                sx: {
                  marginBottom: '-15px',
                  textTransform: 'uppercase',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  color: blueGrey,
                },
              }}
            />
            <CardContent>
              <PricingNotesPanel />
            </CardContent>
          </Card>
        </Grid>
        <SalvageSection />
        <WithRecord
          render={(record) => {
            const isSalvage = record.leadContact.vehicle.tooGoodToScrap
            if (isSalvage) {
              return (
                <React.Fragment>
                  <Grid
                    id="capPanel"
                    style={{
                      gridColumn: '1 / span 6',
                      gridRowStart: 7,
                      gridRowEnd: 7,
                    }}
                  >
                    <Card>
                      <CardContent>
                        <CapPanel />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid
                    style={{
                      gridColumn: '1 / span 6',
                      gridRowStart: 8,
                      gridRowEnd: 8,
                    }}
                  >
                    <Card className="vehicle-adjustments">
                      <CardContent>
                        <Adjustments />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid
                    id="customerNotesPanel"
                    style={{
                      gridColumn: '1 / span 3',
                      gridRowStart: 9,
                      gridRowEnd: 9,
                    }}
                  >
                    <Card sx={{ height: '100%' }}>
                      <CardContent>
                        <CardHeader
                          title="Customer submitted notes & photos"
                          sx={{
                            padding: '10px',
                            marginBottom: '1rem',
                          }}
                          titleTypographyProps={{
                            sx: {
                              marginBottom: '-15px',
                              textTransform: 'uppercase',
                              fontSize: '14px',
                              fontWeight: 'bold',
                              color: blueGrey,
                            },
                          }}
                        />
                        <CustomerNotesPanel />
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid
                    id="vehicleNotesPanel"
                    style={{
                      gridColumn: '4 / span 3',
                      gridRowStart: 9,
                      gridRowEnd: 9,
                    }}
                  >
                    <Card>
                      <CardContent>
                        <CardHeader
                          title="Vehicle Notes"
                          sx={{
                            padding: '10px',
                            marginBottom: '1rem',
                          }}
                          titleTypographyProps={{
                            sx: {
                              marginBottom: '-15px',
                              textTransform: 'uppercase',
                              fontSize: '14px',
                              fontWeight: 'bold',
                              color: blueGrey,
                            },
                          }}
                        />
                        <VehicleNotesPanel
                          notes={notes}
                          saveNewVehicleNote={saveNewVehicleNote}
                          editable={true}
                          setNotes={setNotes}
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                </React.Fragment>
              )
            }
          }}
        />

        <Grid
          id="collectionAgentsPanel"
          style={{
            gridColumn: '1 / span 6',
            gridRowStart: nextGridRow,
            gridRowEnd: nextGridRow,
          }}
        >
          <Card>
            <CardContent sx={{ padding: '0 !important' }}>
              <ViewAgentsPanel
                postcodeChanged={postcodeChanged}
                setPostCodeChanged={setPostcodeChanged}
              />
            </CardContent>
          </Card>
        </Grid>
        <Grid
          style={{
            gridColumn: '1 / span 6',
            gridRowStart: nextGridRow + 1,
            gridRowEnd: nextGridRow + 1,
          }}
        >
          <BookInPanel
            notes={notes}
            saveNewVehicleNote={saveNewVehicleNote}
            setNotes={setNotes}
          />
        </Grid>
      </Grid>
      <Grid
        style={{
          gridColumn: '1 / span 6',
          gridRowStart: nextGridRow + 2,
          gridRowEnd: nextGridRow + 2,
        }}
      >
        <MitelDataPanel />
      </Grid>
    </Edit>
  )
}
export default EditQuoteRequest
