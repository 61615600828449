import {
  EditBase,
  email,
  Form,
  FormDataConsumer,
  required,
  TextInput,
  useNotify,
  useRecordContext,
  useRefresh,
  useUpdate,
} from 'react-admin'
import { Grid, IconButton, InputAdornment } from '@mui/material'
import { Phone } from '@mui/icons-material'
import PostcodeLookup from '../../PostcodeLookup'
import { validateAutoSaveSingleInput } from '../../../../Utils/AutoSave'
import PropTypes from 'prop-types'
import { blockBlankCharFromInput } from '../../../../Utils/ConversionUtils'
import { useSearchParams } from 'react-router-dom'

const LeadDetailsPanel = (props) => {
  const record = useRecordContext()
  const [update, { isLoading, error }] = useUpdate()
  const notify = useNotify()
  const refresh = useRefresh()
  const roles = localStorage.getItem('permissions').split(',')
  const [params] = useSearchParams()
  const receivedNumber = params.get('number')
  const lastValue = record.leadContact.telNumber

  if (receivedNumber && receivedNumber !== lastValue) {
    record.leadContact.telNumber = receivedNumber
    update('lead-contacts', {
      id: record.leadContact['@id'],
      data: { telNumber: receivedNumber },
      previousData: record.leadContact,
    })
  }
  const handleDialNumber = (number, quoteId) => {
    window.open('tel:' + number + '&accountcode=' + quoteId, '_blank').focus()
    props.setContactLogScreen('add-contact-log')
    props.setHistoryPanelSelectedTab(1)

    const element = document.getElementById('start-call-container')
    element.scrollIntoView(true)
  }

  return (
    <EditBase>
      <Form mode="onBlur" reValidateMode="onBlur">
        <Grid container spacing={1}>
          <Grid item flex={1}>
            <TextInput
              label="Customer Name"
              source="leadContact.fullName"
              onBlur={async (e) =>
                await validateAutoSaveSingleInput(
                  record,
                  e.target,
                  notify,
                  refresh
                )
              }
            />
          </Grid>
          <Grid item flex={1}>
            <TextInput
              label="Alternative name"
              source="leadContact.contactName"
              onBlur={async (e) =>
                await validateAutoSaveSingleInput(
                  record,
                  e.target,
                  notify,
                  refresh
                )
              }
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid item flex={1}>
            <TextInput
              label="Contact Number"
              source="leadContact.telNumber"
              onKeyDown={blockBlankCharFromInput}
              onBlur={async (e) => {
                await validateAutoSaveSingleInput(
                  record,
                  e.target,
                  notify,
                  refresh
                )
                if (receivedNumber) {
                  const url = new URL(window.location)
                  const baseURL = url.href.substring(0, url.href.indexOf('?'))
                  const dialledNumber = params.get('dialled')
                  url.searchParams.set('number', e.target.value)
                  url.searchParams.set('dialled', dialledNumber)
                  history.pushState(
                    null,
                    null,
                    baseURL + '' + url.search.toString()
                  )
                }
              }}
              validate={[required()]}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FormDataConsumer>
                      {({ formData, ...rest }) =>
                        formData.leadContact.telNumber && (
                          <IconButton
                            aria-label="call primary contact number"
                            onClick={() =>
                              handleDialNumber(
                                formData.leadContact.telNumber,
                                record.originId
                              )
                            }
                            edge="end"
                          >
                            <Phone color="primary" />
                          </IconButton>
                        )
                      }
                    </FormDataConsumer>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item flex={1}>
            <TextInput
              label="Alternative Number"
              source="leadContact.altTelNumber"
              onKeyDown={blockBlankCharFromInput}
              onBlur={async (e) =>
                await validateAutoSaveSingleInput(
                  record,
                  e.target,
                  notify,
                  refresh
                )
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FormDataConsumer>
                      {({ formData, ...rest }) =>
                        formData.leadContact.altTelNumber && (
                          <IconButton
                            aria-label="call alternative contact number"
                            onClick={() =>
                              handleDialNumber(
                                formData.leadContact.altTelNumber
                              )
                            }
                            edge="end"
                          >
                            <Phone color="primary" />
                          </IconButton>
                        )
                      }
                    </FormDataConsumer>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item flex={1}>
            <PostcodeLookup setPostCodeChanged={props.setPostCodeChanged} />
          </Grid>
          <Grid item flex={2}>
            <TextInput
              label="Address Line 1"
              source="leadContact.addressLine1"
              onBlur={async (e) =>
                await validateAutoSaveSingleInput(
                  record,
                  e.target,
                  notify,
                  refresh
                )
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item flex={2}>
            <TextInput
              label="Address Line 2"
              source="leadContact.addressLine2"
              onBlur={async (e) =>
                await validateAutoSaveSingleInput(
                  record,
                  e.target,
                  notify,
                  refresh
                )
              }
            />
          </Grid>
          <Grid item flex={1}>
            <TextInput
              label="Town"
              source="leadContact.town"
              onBlur={async (e) =>
                await validateAutoSaveSingleInput(
                  record,
                  e.target,
                  notify,
                  refresh
                )
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item flex={1}>
            <TextInput
              label="County"
              source="leadContact.county"
              onBlur={async (e) =>
                await validateAutoSaveSingleInput(
                  record,
                  e.target,
                  notify,
                  refresh
                )
              }
            />
          </Grid>
          <Grid item flex={2}>
            <TextInput
              label="Email Address"
              type={'email'}
              source="leadContact.email"
              validate={[required(), email()]}
              onBlur={async (e) =>
                await validateAutoSaveSingleInput(
                  record,
                  e.target,
                  notify,
                  refresh
                )
              }
            />
          </Grid>
        </Grid>
      </Form>
    </EditBase>
  )
}

export default LeadDetailsPanel

LeadDetailsPanel.propTypes = {
  setContactLogScreen: PropTypes.func,
  setHistoryPanelSelectedTab: PropTypes.func,
  setPostCodeChanged: PropTypes.func,
}
