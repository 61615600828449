import { Grid } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

const LeadDetails = ({ sb }) => {
  return (
    <React.Fragment>
      <Grid item xs={5}></Grid>
      <Grid item xs={2}>
        <strong>Vehicle Assigned to Copart</strong> <br />
        Lot:{' '}
        <a
          href={'http://seller.copart.co.uk/lotdisplay/' + sb.lot_number}
          target="_blank"
          rel="noreferrer"
        >
          {sb.lot_number}{' '}
        </a>
        {sb.shore_cars_details && (
          <>
            <br />
            Reserve: {sb.reserve} <br />
            Purchase Price: {sb.shore_cars_details.purchase_price ?? 'N/A'}{' '}
            <br />
            Extra Work Cost: {sb.shore_cars_details.extra_work_cost ??
              'N/A'}{' '}
            <br />
            Drive Condition:{' '}
            <span style={{ textTransform: 'capitalize' }}>
              {sb.shore_cars_details.drive_condition === 'doesnt_start'
                ? "Doesn't Start"
                : sb.shore_cars_details.drive_condition ?? 'N/A'}
            </span>
            <br />
            Roll Condition:{' '}
            <span style={{ textTransform: 'capitalize' }}>
              {sb.shore_cars_details.roll_condition === 'doesnt_roll'
                ? "Doesn't Roll"
                : sb.shore_cars_details.roll_condition ?? 'N/A'}
            </span>
            <br />
            Address Line 1: {sb.shore_cars_details.address_line_1 ?? 'N/A'}
            <br />
            Address Line 2: {sb.shore_cars_details.address_line_2 ?? 'N/A'}
            <br />
            City: {sb.shore_cars_details.city ?? 'N/A'}
            <br />
            Postcode: {sb.shore_cars_details.postcode ?? 'N/A'}
            <br />
            Primary Damage: {sb.shore_cars_details.primary_damage ?? 'N/A'}
            <br />
            Stripped Parts: {sb.shore_cars_details.stripped_parts ?? 'N/A'}
            <br />
            Has Keys?: {sb.shore_cars_details.has_keys ? 'Yes' : 'No'}
            <br />
            Personals Removed?:{' '}
            {sb.shore_cars_details.personals_removed ? 'Yes' : 'No'}
            <br />
            Transporter Access:{' '}
            {sb.shore_cars_details.transporter_access ? 'Yes' : 'No'}
          </>
        )}
      </Grid>
    </React.Fragment>
  )
}
LeadDetails.propTypes = {
  sb: PropTypes.object,
}
export default LeadDetails
