import React from 'react'
import { Button, Dialog, DialogTitle, DialogActions } from '@mui/material'
import { reassignLead } from '../../../Utils/RestUtils'
import { SHORE_CARS_AGENT_ID } from '../../../Utils/ConstantsUtils'
import { validateAutoSaveSingleInput } from '../../../Utils/AutoSave'
import { useNotify, useRecordContext, useRefresh } from 'react-admin'
import PropTypes from 'prop-types'

function ReassignLeadButton({ currentAgentId, newAgentId, buttonLabel }) {
  const [open, setOpen] = React.useState(false)
  const refresh = useRefresh()
  const notify = useNotify()
  const record = useRecordContext()

  const TransferLead = async () => {
    const inputElement = document.createElement('input')

    inputElement.setAttribute(
      'name',
      'leadContact.vehicle.vehicleSalvage.assignedTo'
    )
    inputElement.setAttribute(
      'value',
      newAgentId === SHORE_CARS_AGENT_ID ? 'cars' : 'salvage'
    )
    setOpen(false)

    await reassignLead(record.originId, currentAgentId, newAgentId)
    await validateAutoSaveSingleInput(record, inputElement, notify, refresh)
    refresh()
  }

  return (
    <>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to transfer the lead?
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>No</Button>
          <Button onClick={() => TransferLead()} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        sx={{
          border: '2px solid #FFF !important',
          backgroundColor: '#ED9C23',
          color: '#ffffff',
          height: '40px',
          width: '100%',
          '&:hover': {
            backgroundColor: '#ED9C23',
          },
        }}
        variant="contained"
        color="secondary"
        onClick={() => setOpen(true)}
      >
        {buttonLabel}
      </Button>
    </>
  )
}

ReassignLeadButton.propTypes = {
  currentAgentId: PropTypes.string,
  newAgentId: PropTypes.string,
  buttonLabel: PropTypes.string,
}

export default ReassignLeadButton
